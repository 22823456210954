import React, { memo, useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Cancel,
  Delete,
  DeleteForever,
  Error,
  Store,
} from "@mui/icons-material";
import {
  Box,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  ListItemButton,
} from "@mui/material";
import { tienePermisoRuta } from "../../../../config/validaciones";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { permisoDenagado } from "../../../../config/const";
import AuthContext from "../../../../Context/Auth/AuthContext";
import MenuContext from "../../../../Context/Menu/MenuContext";
import { withRouter } from "react-router-dom";
import {
  IntegrationInstructions,
  PublishedWithChanges,
} from "@mui/icons-material";
import { serverphp } from "../../../../config/const";
import { ModalRetenciones } from "./ModalRetenciones";
import ConfirmacionDialog from "../../../components/Dialogs/ConfirmacionDialog";
import clienteAxios from "../../../../config/axios";
const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  avatar2: {
    backgroundColor: theme.palette.secondary.main,
  },
  delete: {
    backgroundColor: theme.palette.error.main,
  },
  bg: {
    backgroundColor: theme.palette.primary.dark,
  },
}));
const ExportDialog = (props) => {
  const classes = useStyles();
  const {
    open,
    setopen,
    setopenConfirmDialog,
    row,
    // setopenModalEmail,
    setopenModal,
    changeObjetoActivoState,
    editarState,
  } = props;

  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);
  const [openModalRetencion, setopenModalRetencion] = useState(false);
  const [openConfirmDialogRetencion, setopenConfirmDialogRetencion] = useState(
    false
  );
  return (
    <Dialog
      onClose={() => setopen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {/* <ModalRetenciones
        open={openModalRetencion}
        setopen={setopenModalRetencion}
        row={row}
      /> */}

      <Box padding={2}>
        <DialogTitle id="simple-dialog-title">Acciones.</DialogTitle>
        <List>
          <ListItem
            button
            disabled={row.venEstado === "AUTORIZADO"}
            onClick={() => {
              if (
                !tienePermisoRuta(
                  rows,
                  props.location.pathname,
                  usuario.rol,
                  "eliminar"
                )
              ) {
                return mostrarAlerta(permisoDenagado, "error");
              }
              setopenConfirmDialog(true);
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.delete}>
                <DeleteForever />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Eliminar" />
          </ListItem>{" "}
          <ListItem
            button
            disabled={!(row.venEstado === "AUTORIZADO")}
            onClick={() => {
              // Abres la nueva ventas

              window.open(
                `${serverphp}/reportes/xml.php?letra=${row.venTipoDocumento}&empresa=${usuario.rucempresa}&codigo=${row.venAutorizacion}`,
                "_blank"
              );
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <IntegrationInstructions />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="XML" />
          </ListItem>{" "}
          {/* <ListItem
            button
            disabled={
              !(
                row.venEstado === "AUTORIZADO" ||
                row.venEstado === "ANULADA" ||
                row.venEstado === "ACTIVA" ||
                row.venEstado === "SIN AUTORIZAR"
              )
            }
            onClick={() => {
              setopenModalEmail(true);
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <Email />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Correo" />
          </ListItem> */}
          <ListItem
            button
            disabled={
              !(row.venEstado === "AUTORIZADO" && row.venTipoDocumento === "F")
            }
            onClick={() => {
              setopenModal(true);
              changeObjetoActivoState({
                ...row,
                venOption: "4",
                venEstado: "GENERAR XML",
                venTipoDocumento: "NC",
                venNumFacturaAnterior: row.venNumFactura,
                fechaSustentoFactura: row.venFechaFactura,
                venProductosAntiguos: row.venProductos,
                deshabilitarCampos: true,
                venFechaFactura: new Date(
                  Date.now() - new Date().getTimezoneOffset() * 60 * 1000
                )
                  .toISOString()
                  .substring(0, 10),
              });
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PublishedWithChanges />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Nota Crédito" />
          </ListItem>
          <ListItem
            button
            disabled={!(row.venErrorAutorizacion === "SI")}
            onClick={() => {
              window.open(
                `${serverphp}/procesosSRI/errores/${row.venEmpresa}/${row.venTipoDocumento}${row.venAutorizacion}.txt `,
                "_blank"
              );
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.delete}>
                <Error />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Error" />
          </ListItem>
          {/* <ListItemButton
            disabled={
              Object.keys(JSON.parse(row.venOtros || "{}")).length === 0
            }
            onClick={() => setopenModalRetencion(true)}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <Store />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Retención" />
          </ListItemButton> */}
          <ListItemButton
            disabled={
              Object.keys(JSON.parse(row.venOtros || "{}")).length === 0
            }
            onClick={() => {
              setopenConfirmDialogRetencion(true);
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.delete}>
                <Delete />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Eliminar Retención" />
          </ListItemButton>
        </List>
        <ConfirmacionDialog
          open={openConfirmDialogRetencion}
          setopen={setopenConfirmDialogRetencion}
          categoria="warning"
          titulo={`¿Esta seguro de eliminar la retención?`}
          botones={[
            {
              tooltip: "Se eliminaran los registros",
              texto: "SI",
              funcion: async () => {
                try {
                  console.log(`ventas/eliminar/retencion/${row.venId}`);
                  const res = await clienteAxios.get(
                    `ventas/eliminar/retencion/${row.venId}`
                  );
                  setopenConfirmDialogRetencion(false);
                  editarState(res.data);
                  console.log({ res });
                  setopen(false);
                } catch (error) {
                  mostrarAlerta("Hubo un error");
                  setopenConfirmDialogRetencion(false);
                }
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: { marginRight: ".3rem" },
            },
            {
              tooltip: "Cancelar",
              texto: "NO",
              funcion: () => {
                setopenConfirmDialogRetencion(false);
              },
              disabled: false,
              Icon: Cancel,
              color: "secondary",
              id: 2,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
        />
      </Box>
    </Dialog>
  );
};

export default memo(withRouter(ExportDialog));
